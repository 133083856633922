import React from "react";
import Layout from "../../components/layout/layout";
import { StaticImage } from "gatsby-plugin-image";

import SEO from "../../components/seo";

const svp = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Singapore VisionCare Programme"
          description="Singapore Women’s Association (SWA) in collaboration with other voluntary bodies: the National Safety Council, the Association for the Visually Handicapped, NTUC and the Singapore National Eye Centre founded the Singapore VisionCare Programme(SVP)."
        />
        <section className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <div className="relative px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="absolute top-0 bottom-0 hidden w-screen lg:block bg-swa-4 bg-opacity-20 left-3/4 z-n10"></div>
            <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
              <div>
                {/* <h2 className="text-base font-semibold tracking-wide text-indigo-600 uppercase">
                  Case Study
                </h2> */}
                <h3 className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-swa-1 sm:text-4xl">
                  Singapore VisionCare Programme
                </h3>
              </div>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative lg:row-start-1 lg:col-start-2">
                <svg
                  className="absolute top-0 right-0 hidden -mt-20 -mr-20 lg:block"
                  width="404"
                  height="384"
                  fill="none"
                  viewBox="0 0 404 384"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="404"
                    height="384"
                    fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                  />
                </svg>
                <div className="relative mx-auto text-base max-w-prose lg:max-w-none">
                  <figure>
                    <StaticImage
                      className="object-cover object-center rounded-lg shadow-lg"
                      src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636892714/svpPage/nmydqhxndexjwqyeznva.jpg"
                      alt="Whitney leaning against a railing on a downtown street"
                    />

                    <figcaption className="flex mt-3 text-sm text-gray-600">
                      <span className="ml-2">
                        Singapore Vision Care Programme - 2017
                      </span>
                    </figcaption>
                  </figure>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                <div className="mx-auto text-base max-w-prose lg:max-w-none">
                  <p className="text-xl leading-8 text-gray-500">
                    Singapore Women’s Association (SWA) in collaboration with
                    other voluntary bodies: the National Safety Council, the
                    Association for the Visually Handicapped, NTUC and the
                    Singapore National Eye Centre founded the Singapore
                    VisionCare Programme(SVP). Mr Yeo Cheow Tong, then Acting
                    Minister for Health, launched the Singapore VisionCare
                    Programme on 22nd April 1990. Since then, many of our
                    members have assiduously sacrificed their weekends to run
                    this programme.
                  </p>
                </div>
                <div className="mx-auto mt-5 text-lg text-swa-1 prose-indigo lg:max-w-none lg:row-start-1 lg:col-start-1">
                  <h3>Our objectives</h3>
                  <ul className="p-3 my-5 space-y-5 bg-opacity-50 rounded-lg bg-swa-4">
                    <li>
                      Public education on the prevention of blindness, eye-care,
                      eye diseases and treatment available
                    </li>
                    <li>
                      To deliver eye care services to the community for early
                      detection and prevention of any eye disorders, thus
                      improving vision and quality of life.
                    </li>
                    <li>
                      To reach out to the elderly above the age of 40 to detect
                      any eye problems: in partnership with community clubs, old
                      folks’ homes and mosques, volunteer doctors (from the
                      Singapore National Eye Centre(SNEC); National University
                      Hospital(NUH); Tan Tock Seng hospital and those in private
                      practice) along with swa members conduct free eye
                      screening sessions on sundays.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <section className="mx-1 mt-10 text-xl font-light border-black text-swa-2">
              <div className="grid grid-cols-2 my-5 gap-x-1 gap-y-1 sm:grid-cols-3 sm:gap-x-1 lg:grid-cols-4 xl:gap-x-1">
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636892704/svpPage/kie4kxnfsapcj961wbjt.jpg"
                    alt="Singapore Vision Care Programme"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 0%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636892694/svpPage/lhpbfidxrrs9c9diqjpu.jpg"
                    alt="Singapore Vision Care Programme"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 0%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637227914/svpPage/s2j0f3kgvanid0gvm1bb.jpg"
                    alt="Singapore Vision Care Programme"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 0%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637227984/svpPage/azvpatkvqamfvo5c5zam.jpg"
                    alt="Singapore Vision Care Programme"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 50%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228174/svpPage/mpnge2obfgcwnvdpr5mc.jpg"
                    alt="Singapore Vision Care Programme"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 0%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228110/svpPage/vk0i08yuxoxmr5jga02m.jpg"
                    alt="Singapore Vision Care Programme"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 0%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636893629/svpPage/vfbk6xkkgkvou1czqswq.jpg"
                    alt="Singapore Vision Care Programme"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 50%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637228059/svpPage/pdabl9lsnzbnx64o6is0.jpg"
                    alt="Singapore Vision Care Programme"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 0%"}
                  />
                </div>
              </div>
            </section>

            <div className="mx-auto my-10 text-base max-w-prose lg:max-w-none">
              <p className="text-xl leading-8 text-gray-500">
                Since its launch in 1990, SVP has been running its programme in
                collaboration with other voluntary bodies including the National
                Safety Council, NTUC, Singapore Association for the Visually
                Handicapped (SAVH), NUH, the National Eye Centre and private
                doctors. This well-received initiative aims to reach out to the
                elderly and educate them on the importance of eye care, as well
                as the prevention of eye diseases. Anny Leow is the Co-Chair and
                Dr Khoo Chong Yew is the Chairperson. Dr Khoo passed on 16 Nov
                at the age of 78. The exco approved that Dr Stephanie Young will
                be the Chairperson of SVP and Anny Leow will continue to be the
                co-chairperson. SWA conducted on average 10 screenings each year
                and over 1000 senior citizens are screened in the Old Folks
                Home, Community Centers and Community Clubs annually. Project I
                Care is a project supported by NWCDC, in which we have been
                doing eye screening for the home for the past ten years.
              </p>
            </div>
            <div className="mx-auto my-5 bg-gray-500 rounded-lg p3">
              <StaticImage
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636892710/svpPage/ybbntajxjsqpzlmgngmm.jpg"
                alt="Singapore VisionCare Programme"
              />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default svp;
